import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import { Icon } from "@mui/material";
import { Link } from "react-router-dom";

import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography"


// Material Kit 2 React examples
import CenteredBlogCard from "../../../examples/Cards/BlogCards/CenteredBlogCard";

// Images
import rigvImage from "../../../assets/images/sections/KidsSafetyZone.webp"
import Personal from "../../../assets/images/banner/personal-vehicle.svg"

function RigVSection({ color, title, description, direction, small, action }) {
    // const icon = "3p"

    const cardActionStyles = {
        pt: 3,
        display: "flex",
        alignItems: "center",
        width: "max-content",

        "& .material-icons, .material-icons-round,": {
            transform: `translateX(2px)`,
            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
        },

        "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
        {
            transform: `translateX(6px)`,
        },
    };
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6} sx={{ mt: { xs: 3, lg: 0 } }}>
                        <Grid container justifyContent="flex-start">
                            <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
                                {/* <MKTypography
                                    display="block"
                                    variant="5"
                                    fontWeight="bold"
                                    mt={direction === "center" ? 1 : 2}
                                    mb={1.5}
                                >
                                    Features
                                </MKTypography> */}
                                {/* <MKTypography
                                    display="block"
                                    variant="h1"
                                    fontWeight="bold"
                                    mt={direction === "center" ? 1 : 2}
                                    mb={1.5}
                                >
                                    The best way to keep your kids safe
                                    Personal Vehicle Tracking
                                </MKTypography> */}
                                <MKBox
                                    style={{ marginBottom: 30, marginTop: 30 }}
                                    component="img"
                                    src={Personal}
                                    alt={Personal}
                                    borderRadius="lg"
                                    width="90%"
                                    position="relative"
                                    zIndex={1}
                                />
                                <MKTypography
                                    display="block"
                                    variant={small ? "button" : "body2"}
                                    color="text"
                                    pr={direction === "left" ? 6 : 0}
                                    pl={direction === "right" ? 6 : 0}
                                >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </MKTypography>
                                {/* {action.type === "internal" ? ( */}
                                <MKTypography
                                    component={Link}
                                    to="/products/rigv"
                                    variant="body2"
                                    fontWeight="regular"
                                    color="#618a3d"
                                    // textTransform="capitalize"
                                    sx={cardActionStyles}
                                >
                                    Read More
                                    {/* <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}

                                    <ArrowRightAlt
                                        // color="secondary"
                                        sx={{ fontWeight: "bold", ml: 1, fontSize: 40 }}
                                    />
                                </MKTypography>
                                {/* ) : (
                                    <MKTypography
                                        component={MuiLink}
                                        href={action.route}
                                        target="_blank"
                                        rel="noreferrer"
                                        variant="body2"
                                        fontWeight="regular"
                                        color="white"
                                        textTransform="capitalize"
                                        sx={cardActionStyles}
                                    >
                                        {action.label}
                                        <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                                    </MKTypography>
                                )} */}
                            </MKBox>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                        <CenteredBlogCard
                            // image="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                            image={rigvImage}
                            title="Get insights on Search"
                            description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
                            action={{
                                type: "internal",
                                route: "pages/company/about-us",
                                color: "info",
                                label: "find out more",
                            }}
                        />
                    </Grid>
                </Grid>
                {/* <Divider
                // sx={{ my: 6 }} 
                /> */}

            </Container>
        </MKBox>
    );
}

export default RigVSection;
