// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

function ClientCard({ image, name, count, pro, ...rest }) {
    const imageTemplate = (
        <MKBox
            style={{ justifyContent: "center" }}
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            minHeight="10rem"
            maxHeight="10rem"
            sx={{
                px: 4,
                py: 4,
                overflow: "hidden",
                backgroundSize: "contain",
                transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
                transformOrigin: "50% 0",
                backfaceVisibility: "hidden",
                willChange: "transform, box-shadow",
                transition: "transform 200ms ease-out",

                "&:hover": {
                    transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
                },
            }}
            {...rest}
        >
            <MKBox
                component="img"
                src={image}
                // style={{ width: "95%", height: "150", margin: 5 }}
                alt={name}
                width="100%"
                height="50%"
                my="auto"
                opacity={pro ? 0.6 : 1}
            />
        </MKBox>
    );

    return (
        <MKBox position="relative">
            {pro ? (
                <Tooltip title="Pro Element" placement="top">
                    {imageTemplate}
                </Tooltip>
            ) : (
                imageTemplate
            )}
            {name || count > 0 ? (
                <MKBox mt={1} ml={1} lineHeight={1}>
                    {name && (
                        <MKTypography variant="h6" fontWeight="bold">
                            {name}
                        </MKTypography>
                    )}
                </MKBox>
            ) : null}
        </MKBox>
    );
}

// Setting default props for the ExampleCard
ClientCard.defaultProps = {
    name: "",
    count: 0,
    pro: false,
};

// Typechecking props for the ExampleCard
ClientCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
    count: PropTypes.number,
    pro: PropTypes.bool,
};

export default ClientCard;
