// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

function PlainBlogCard({ image, title, description, action }) {

    return (
        <Card
            sx={{
                backgroundImage: ({ palette: { black }, functions: { linearGradient, rgba } }) =>
                    `${linearGradient(rgba(black.main, 0.5), rgba(black.main, 0.5))}, 
                    // url(${image})`,
                // backgroundSize: "cover",
            }}
        >
            <MKBox p={3} >
                <MKBox minHeight="20.625rem" my="auto" py={3}>
                    <MKTypography
                        variant="h3"
                        // color="white"
                        color="text"
                        mb={1}
                        pb={3}
                        sx={({ breakpoints, typography: { size } }) => ({
                            [breakpoints.down("md")]: {
                                fontSize: size["3xl"],
                            },
                        })}
                    >
                        {title}
                    </MKTypography>
                    <MKTypography variant="body2" textAlign={"justify"}
                        // color="white" 
                        color="text"
                        my={3}>
                        {description}
                    </MKTypography>
                </MKBox>
            </MKBox>
        </Card>
    );
}

// Typechecking props for the BackgroundBlogCard
PlainBlogCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["external", "internal"]).isRequired,
        route: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

export default PlainBlogCard;
