/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
// import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";


import AboutUs from "./layouts/pages/landing-pages/about-us";
import Rigv from "./layouts/pages/landing-pages/rigv";
import SchoolBus from "./layouts/pages/landing-pages/school";
import CoalMining from "./layouts/pages/landing-pages/coal-mining";
import Career from "./layouts/pages/landing-pages/career";

// icon
import GridViewIcon from '@mui/icons-material/GridView';

// Pages
// import AboutUs from "layouts/pages/landing-pages/about-us";
// import ContactUs from "layouts/pages/landing-pages/contact-us";
// import Author from "layouts/pages/landing-pages/author";
// import SignIn from "layouts/pages/authentication/sign-in";
// import Gallary from "layouts/pages/landing-pages/gallary";
// import CompanyIntroduction from "./layouts/pages/landing-pages/company-introduction";
// import OurTeam from "./layouts/pages/landing-pages/our-team";
// import MissionVision from "./layouts/pages/landing-pages/mission-vision";
// import OurValues from "./layouts/pages/landing-pages/our-values";
// import OurClients from "./layouts/pages/landing-pages/our-clients";
// import Gallary from "./layouts/pages/landing-pages/gallary";
// import Testimonial from "./layouts/pages/landing-pages/testimonial";

const routes = [
  {
    name: "About Us",
    // icon: <GitHubIcon />,
    route: "/about",
    component: <AboutUs />,
  },
  {
    name: "Products",
    icon: <GridViewIcon />,
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Our Products",
        collapse: [
          {
            name: "RigV School",
            route: "/products/school",
            component: <SchoolBus />,
          },
          {
            name: "RigV",
            route: "/products/rigv",
            component: <Rigv />,
          },
          {
            name: "Coal Mining",
            route: "/products/coalmining",
            component: <CoalMining />,
          },
        ],
      },
    ],
  },
  // {
  //   name: "Mission & Value",
  //   // icon: <GitHubIcon />,
  //   route: "/mission-value",
  //   // component: <Author />,
  // },
  {
    name: "Career",
    icon: <GitHubIcon />,
    route: "/career",
    component: <Career />,
  },

];

export default routes;
