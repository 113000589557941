
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import borders from "../../../../../assets/theme/base/borders";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import MKTypography from "../../../../../components/MKTypography";

function RotatingCardFront({ color, image, icon, title, description }) {
    const { borderWidth, borderColor } = borders;

    return (
        <MKBox
            display="flex"
            justifyContent="center"
            alignContent="center"
            borderRadius="lg"
            coloredShadow={color}
            width="100%"
            minHeight="30rem"
            maxHeight="30rem"
            position="relative"
            zIndex={2}
            sx={{
                backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                    `${linearGradient(
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
                        rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85)
                    )}, url(${image})`,
                backgroundSize: "cover",
                backfaceVisibility: "hidden",
                backgroundColor: "transparent"
            }}
        >
            <MKBox py={12} px={3} textAlign="center" lineHeight={1}
                sx={{
                    marginY: "auto",
                    paddingX: 5,
                    paddingY: 1,
                    border: `${borderWidth[1]} solid ${borderColor}`,
                }}
            >
                {icon && (
                    <MKTypography variant="h2" color="white" my={2}>
                        {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
                    </MKTypography>
                )}
                <MKTypography variant="h4" color="white" gutterBottom>
                    {title}
                </MKTypography>

                <MKTypography variant="body2" color="Text" opacity={0.8}>
                    {description}
                </MKTypography>
            </MKBox>
        </MKBox>
    );
}

// Setting default props for the RotatingCardFront
RotatingCardFront.defaultProps = {
    color: "info",
    icon: "",
};

// Typechecking props for the RotatingCardFront
RotatingCardFront.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    image: PropTypes.string.isRequired,
    icon: PropTypes.node,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
};

export default RotatingCardFront;
