// import { Icon } from "@mui/material";
import { Link } from "react-router-dom";

import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography"

// Material Kit 2 React examples
import CenteredBlogCard from "../../../examples/Cards/BlogCards/CenteredBlogCard";

import schoolImage from "../../../assets/images/sections/DeviceForKidsCardImg.webp"
import School from "../../../assets/images/banner/school-bus-tracking.svg"

function SchoolSection({ color, title, description, direction, small }) {
    const cardActionStyles = {
        pt: 3,
        display: "flex",
        alignItems: "center",
        width: "max-content",

        "& .material-icons, .material-icons-round,": {
            transform: `translateX(2px)`,
            transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
        },

        "&:hover .material-icons, &:focus .material-icons, &:hover .material-icons-round, &:focus .material-icons-round":
        {
            transform: `translateX(6px)`,
        },
    };
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={4} sx={{ mt: { xs: 3, lg: 0 } }}>
                        <CenteredBlogCard
                            image={schoolImage}
                            title="Get insights on Search"
                            description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
                            action={{
                                type: "internal",
                                route: "/about",
                                color: "info",
                                label: "find out more",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                        <Grid container justifyContent="flex-start">
                            <MKBox lineHeight={1} p={direction === "center" ? 2 : 0} textAlign={direction}>
                                <MKBox
                                    style={{ marginBottom: 30, marginTop: 30 }}
                                    component="img"
                                    src={School}
                                    alt={School}
                                    borderRadius="lg"
                                    width="90%"
                                    position="relative"
                                    zIndex={1}
                                />
                                <MKTypography
                                    display="block"
                                    variant={small ? "button" : "body2"}
                                    color="text"
                                    pr={direction === "left" ? 6 : 0}
                                    pl={direction === "right" ? 6 : 0}
                                >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </MKTypography>

                                <MKTypography
                                    component={Link}
                                    to="/products/school"
                                    variant="body2"
                                    fontWeight="regular"
                                    color="#618a3d"
                                    sx={cardActionStyles}
                                >
                                    Read More
                                    {/* <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon> */}

                                    <ArrowRightAlt
                                        // color="secondary"
                                        sx={{ fontWeight: "bold", ml: 1, fontSize: 40 }}
                                    />
                                </MKTypography>
                            </MKBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default SchoolSection;
