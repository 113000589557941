// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import MKTypography from "../../../../../components/MKTypography";

// Material Kit 2 React examples
// import DefaultReviewCard from "../../../../../examples/Cards/ReviewCards/DefaultReviewCard";


// Images
// import appleLogo from "../../../../../assets/images/logos/gray-logos/logo-apple.svg";
// import facebookLogo from "../../../../../assets/images/logos/gray-logos/logo-facebook.svg";
// import nasaLogo from "../../../../../assets/images/logos/gray-logos/logo-nasa.svg";
// import vodafoneLogo from "../../../../../assets/images/logos/gray-logos/logo-vodafone.svg";
// import digitalOceanLogo from "../../../../../assets/images/logos/gray-logos/logo-digitalocean.svg";

// import firstFeature from "../../../../../assets/images/features/Maintenance-Reminder.png";
import secondFeature from "../../../../../assets/images/features/Route-Playback.png";
// import thirdFeature from "../../../../../assets/images/features/Geofence-.png";
import SchoolFeatureCard from "./SchoolFeatureCard";

function WhyChooseRigvSchool() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <MKTypography variant="h2">Why Choose RigV School?</MKTypography>

                    <MKTypography variant="body1" color="text" mb={2}>
                        Accurate Insights. Real-time Alerts.
                    </MKTypography>
                </Grid>
                <Grid container spacing={3} sx={{ mt: 8 }}>
                    <Grid item xs={12} md={6} lg={4}>
                        <SchoolFeatureCard
                            image={secondFeature}
                            title="Maintenance Reminder"
                            description="Streamline your assets’ maintenance activities with TrakzeeMini’s maintenance reminder system. Cut costs and prevent damages by keeping up with the asset maintenance schedule."
                            action={{
                                type: "internal",
                                route: "/pages/blogs/author",
                                color: "info",
                                label: "read more",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SchoolFeatureCard
                            image={secondFeature}
                            title="Maintenance Reminder"
                            description="Streamline your assets’ maintenance activities with TrakzeeMini’s maintenance reminder system. Cut costs and prevent damages by keeping up with the asset maintenance schedule."
                            action={{
                                type: "internal",
                                route: "/pages/blogs/author",
                                color: "info",
                                label: "read more",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SchoolFeatureCard
                            image={secondFeature}
                            title="Maintenance Reminder"
                            description="Streamline your assets’ maintenance activities with TrakzeeMini’s maintenance reminder system. Cut costs and prevent damages by keeping up with the asset maintenance schedule."
                            action={{
                                type: "internal",
                                route: "/pages/blogs/author",
                                color: "info",
                                label: "read more",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SchoolFeatureCard
                            image={secondFeature}
                            title="Maintenance Reminder"
                            description="Streamline your assets’ maintenance activities with TrakzeeMini’s maintenance reminder system. Cut costs and prevent damages by keeping up with the asset maintenance schedule."
                            action={{
                                type: "internal",
                                route: "/pages/blogs/author",
                                color: "info",
                                label: "read more",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SchoolFeatureCard
                            image={secondFeature}
                            title="Maintenance Reminder"
                            description="Streamline your assets’ maintenance activities with TrakzeeMini’s maintenance reminder system. Cut costs and prevent damages by keeping up with the asset maintenance schedule."
                            action={{
                                type: "internal",
                                route: "/pages/blogs/author",
                                color: "info",
                                label: "read more",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <SchoolFeatureCard
                            image={secondFeature}
                            title="Maintenance Reminder"
                            description="Streamline your assets’ maintenance activities with TrakzeeMini’s maintenance reminder system. Cut costs and prevent damages by keeping up with the asset maintenance schedule."
                            action={{
                                type: "internal",
                                route: "/pages/blogs/author",
                                color: "info",
                                label: "read more",
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default WhyChooseRigvSchool;
