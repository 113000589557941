
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import CenteredFooter from "../../examples/Footers/CenteredFooter";

// Routes
import routes from "../../routes";

// Images
// import bgImage from "../../assets/images/home-screen-banner.jpg";
import banner1 from "../../assets/images/banner/banner01.svg";
import Information from "./section/Information";

// import BusinessSolutions from "./section/BusinessSolutions";
// import CorporateValues from "./section/CorporateValues";

import RigVSection from "./section/RigVSection";
import SchoolSection from "./section/SchoolSection";
import CoalMiningSection from "./section/CoalMiningScreen";
// import DownloadApp from "./section/DownloadApp";
import TestimonialScreen from "./section/TestimonialScreen";
import ClientScreen from "./section/ClientScreen";

function Home() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        variant="gradient"
        bgColor="warning"
        sx={{
          // backgroundImage: `url(${bgImage})`,
          // backgroundSize: "cover",
          // backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container spacing={3} alignItems="center" mx="auto">
            <Grid item xs={12} lg={5} mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                // textAlign="center"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Defining Accuracy...{" "}
                Provinding GPS Tracking & Telematics Solutions
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                mt={1}
              >
                All in one solution for location based services.
                Dynamic GPS Tracking software products that are easy to white-label and customize for your business needs.
              </MKTypography>
            </Grid>
            <Grid item xs={12} lg={7}>
              <MKBox
                minHeight="60vh"
                width="100%"
                sx={{
                  backgroundImage: `url(${banner1})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top",
                  display: "grid",
                  placeItems: "center",
                }}
              ></MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        {/* <BusinessSolutions /> */}
        {/* <CorporateValues /> */}

        <Information />
        <SchoolSection />
        <Divider
          sx={{ my: 6, mx: "15%" }}
        />

        <RigVSection />
        <Divider
          color="red"
          sx={{ my: 6, mx: "15%", color: "#ff0000" }}
        />

        <CoalMiningSection />
        {/* <DownloadApp /> */}
        <TestimonialScreen />
        <ClientScreen />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default Home;
