// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import MKTypography from "../../../../../components/MKTypography";

// Images
import schoolImage from "../../../../../assets/images/sections/VissionAndMission.webp"
// import macbook from "../../../../../assets/images/macbook.png";

function AboutSchoolBus() {
    return (
        <MKBox component="section" pt={6} my={6}>
            <Container>
                <Grid container alignItems="center">
                    <Grid item sx={12} md={6}
                    // sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}
                    >
                        <MKTypography variant="h2" mb={6}>
                            What School Bus is...?
                        </MKTypography>
                        <MKTypography variant="body2" color="text" mb={2} sx={{ lineHeight: 2 }}>
                            &apos; We are DLSPL, and we provide made-to-order IoT software solutions and services. We make use of GPS technology and internet connectivity to empower businesses. We listen well and outdo expectations every time!.....
                            &apos; RigV is free smartphone app which will change the way you interact with location, even if you have slow or no internet. It will empower you to save location and compass it without internet. RigV can monitor your vehicle, kids and luggage. At the same time, serve list of address/places like ATM, hotel, hospital and many more around you sorted by nearest one.
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                        <MKBox position="relative">
                            {/* <MKBox component="img" src={macbook} alt="macbook" width="100%" /> */}
                            <MKBox component="img" src={schoolImage} alt="macbook" width="80%"
                                sx={{ ml: { xs: 0, lg: 5 }, mb: { xs: 12, md: 0 } }} />
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default AboutSchoolBus;
