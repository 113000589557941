// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
// import MKButton from "../../../components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import CenteredFooter from "../../../../examples/Footers/CenteredFooter"

// About Us page sections

// Routes
import routes from "../../../../routes";

// Images
// import bgImage from "../../../../assets/images/bg-about-us.jpg";
import WhyChooseCoalMining from "./section/WhyChooseCoalMining";
import AboutCoalMining from "./section/AboutCoalMining";


// http://coalmining.rigv.in/

function CoalMining() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                variant="gradient"
                bgColor="dark"
                sx={{
                    // backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    //     `${linearGradient(
                    //         rgba(gradients.dark.main, 0.6),
                    //         rgba(gradients.dark.state, 0.6)
                    //     )}, url(${bgImage})`,
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Coal Mining
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <AboutCoalMining />
                <Divider
                    sx={{ my: 6, mx: "15%" }}
                />
                <WhyChooseCoalMining />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <CenteredFooter />
            </MKBox>
        </>
    );
}

export default CoalMining;
