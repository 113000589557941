/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "../../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import managingDirector from "../../../../assets/images/sections/kunal_raut.jpeg";
import director from "../../../../assets/images/sections/vickyKriplani.jpeg";
import team3 from "../../../../assets/images/ivana-squares.jpg";
import team4 from "../../../../assets/images/ivana-square.jpg";

function Team() {
    return (
        <MKBox
            component="section"
            variant="gradient"
            bgColor="white"    //dark
            position="relative"
            py={12}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        >
            <Container>
                <Grid container >
                    <Grid item xs={12} md={12} sx={{ mb: 20, textAlign: "center" }} justifyContent="center"
                        alignItems="center">
                        <MKTypography variant="h3" color="dark" mb={5}>
                            The Executive Team
                        </MKTypography>
                        <MKTypography variant="body2" color="default" opacity={0.8}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </MKTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={2}>
                            <HorizontalTeamCard
                                image={director}
                                name="Vikram Kriplani"
                                position={{ color: "warning", label: "Director," }}
                                description="“Its Not A Smart City Till It Is Safe For Women”. To Make Cities Safer, we have to take up a more coordinated, focused approach, engaging with all stakeholders, including policymakers, the civil society as well as ordinary citizens. Only then will we be able to deal with this problem effectively. 
                                Safety is not dependent on creating infrastructures but it has to do with peoples mind set as well. One can be be safe in the women’s coach of metro but after stepping out."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={2}>
                            <HorizontalTeamCard
                                image={managingDirector}
                                name="KUNAL RAUT"
                                position={{ color: "warning", label: "Managing Director" }}
                                description="We at Dolphin work for making world a better place to live and life a beautiful experience of humanity. We can make this happen by trying to reduce or remove the stress buildups, existing at different work life. Alternatively we are trying to make this by increasing the comfort through our efforts.. Due to the inherent vibrancy in the trade and industry in general and Information Technology sector in particular, it has been a basic and vital factor to have a long term view and focus of the work horizon. Dolphin is established to provide solutions without compromise of the quality and sticking to high ethical work culture."
                            />
                        </MKBox>
                    </Grid>
                    {/* <Grid item xs={12} lg={6}>
                        <MKBox mb={2}>
                            <HorizontalTeamCard
                                image={team1}
                                name="Vikram Kriplani"
                                position={{ color: "warning", label: "Director," }}
                                description="“Its Not A Smart City Till It Is Safe For Women”. To Make Cities Safer, we have to take up a more coordinated, focused approach, engaging with all stakeholders, including policymakers, the civil society as well as ordinary citizens. Only then will we be able to deal with this problem effectively. 
                                Safety is not dependent on creating infrastructures but it has to do with peoples mind set as well. One can be be safe in the women’s coach of metro but after stepping out."
                            />
                        </MKBox>
                    </Grid> */}
                    {/* <Grid item xs={12} lg={6}>
                        <MKBox mb={2}>
                            <HorizontalTeamCard
                                image={team2}
                                name="KUNAL RAUT"
                                position={{ color: "warning", label: "Managing Director" }}
                                description="We at Dolphin work for making world a better place to live and life a beautiful experience of humanity. We can make this happen by trying to reduce or remove the stress buildups, existing at different work life. Alternatively we are trying to make this by increasing the comfort through our efforts.. Due to the inherent vibrancy in the trade and industry in general and Information Technology sector in particular, it has been a basic and vital factor to have a long term view and focus of the work horizon. Dolphin is established to provide solutions without compromise of the quality and sticking to high ethical work culture."
                            />
                        </MKBox>
                    </Grid> */}
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Team;
