// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import MKTypography from "../../../../../components/MKTypography";

// import post4 from "../../../../../assets/images/examples/blog2.jpg";
// import PlainBlogCard from "../../../../../examples/Cards/BlogCards/PlainBlogCard";

// import image from "../../../../../assets/images/sections/bg-17.png"


function AboutRigv() {
    return (
        <MKBox component="section" py={12}
        // sx={{
        //     backgroundImage: ({ functions: { linearGradient, rgba } }) =>
        //         `url(${image})`,
        //     backgroundSize: "cover",
        // }}
        >
            <Container>
                <Grid container spacing={3} sx={{ mx: "auto" }}>
                    <Grid item xs={12} sm={6} lg={12} sx={{ textAlign: "center" }}>
                        <MKTypography variant="h2" mb={6}>
                            What RigV is...?
                        </MKTypography>
                        <MKTypography variant="body1" color="text" mb={2}>
                            &apos; We are DLSPL, and we provide made-to-order IoT software solutions and services. We make use of GPS technology and internet connectivity to empower businesses. We listen well and outdo expectations every time!.....
                            &apos; RigV is free smartphone app which will change the way you interact with location, even if you have slow or no internet. It will empower you to save location and compass it without internet. RigV can monitor your vehicle, kids and luggage. At the same time, serve list of address/places like ATM, hotel, hospital and many more around you sorted by nearest one.
                        </MKTypography>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} lg={3} sx={{ ml: "auto" }}>
                        <PlainBlogCard
                            image={post4}
                            title="Our Philosophy"
                            description="We are a synergistic company, and we hold our employees and partners in high regard. Purpose-driven and disciplined—we get things done right!"
                        />
                    </Grid> */}
                </Grid>
            </Container>
        </MKBox>
    );
}

export default AboutRigv;
