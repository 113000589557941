
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// cards
import RotatingCard from "../../../../examples/Cards/RotatingCard";
import RotatingCardFront from "./Cards/RotatingCardFront";
import RotatingCardBack from "./Cards/RotatingCardBack";


// Images
import bgFront from "../../../../assets/images/rotating-card-bg-front.jpeg";
// import bgBack from "../../../../assets/images/rotating-card-bg-back.jpeg";

import abstract from "../../../../assets/images/abstract/abstract1.webp"

function MissionAndValues() {

    return (
        <MKBox
            display="flex"
            alignItems="center"
            // borderRadius="xl"
            my={2}
            py={6}
            sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    `${linearGradient(
                        rgba(gradients.dark.main, 0.8),
                        rgba(gradients.dark.state, 0.8)
                    )}, url(${abstract})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <Container>
                <Grid container item xs={12} lg={12} py={5}
                >
                    <MKTypography variant="h1" color="white" textAlign="center" style={{ textAlign: "center" }}>
                        Designing the future for value and impact
                    </MKTypography>
                    <Grid container spacing={10} mt={4}>
                        <Grid item xs={12} lg={4}>
                            <RotatingCard>
                                <RotatingCardFront
                                    image={bgFront}
                                    // icon="touch_app"
                                    title="Vision"
                                    color="dark"
                                />
                                <RotatingCardBack
                                    // image={bgBack}
                                    title="Vision"
                                    description="To earn lifetime loyalty of our customers by consistently delivering the highest quality software services which offer excellent value to their business."
                                // action={{
                                //     type: "internal",
                                //     route: "/sections/page-sections/page-headers",
                                //     label: "start with header",
                                // }}
                                />
                            </RotatingCard>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <RotatingCard>
                                <RotatingCardFront
                                    image={bgFront}
                                    // icon="touch_app"
                                    title="Mission"
                                    color="dark"
                                />
                                <RotatingCardBack
                                    // image={bgBack}
                                    title="Mission"
                                    description="To enable businesses to leverage the power of digital innovation – by envisioning & building disruptive software products, services & experiences which drive growth, differentiation & real economic value."
                                // action={{
                                //     type: "internal",
                                //     route: "/sections/page-sections/page-headers",
                                //     label: "start with header",
                                // }}
                                />
                            </RotatingCard>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <RotatingCard>
                                <RotatingCardFront
                                    image={bgFront}
                                    // icon="touch_app"
                                    title="Culture & Values"
                                    color="dark"
                                />
                                <RotatingCardBack
                                    // image={bgBack}
                                    title="Culture & Values"
                                    description="Core values laid by our visionaries form the bedrock of our culture and it clearly defines the roadmap as we serve our stakeholders. Our culture reflects our brand ethos and helps us operate with ethics, integrity, trust, and dignity with our partners, affiliates, customers, and employees."
                                // action={{
                                //     type: "internal",
                                //     route: "/sections/page-sections/page-headers",
                                //     label: "start with header",
                                // }}
                                />
                            </RotatingCard>
                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </MKBox>
    );
}


export default MissionAndValues;



