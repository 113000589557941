// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

import ClientCard from "./card/ClientCard";

// images
import BhavanVidyaMandir from "../../../assets/images/clients/bhava.png"
import DelhiPublicSchool from "../../../assets/images/clients/dpslogo.png"
import Edify from "../../../assets/images/clients/edify.png"
import Gaikwad from "../../../assets/images/clients/gaikwad.png"
import Mahalaxmi from "../../../assets/images/clients/mahalaxmi.png"
import Pooja from "../../../assets/images/clients/pooja.png"
import Siddhivinayak from "../../../assets/images/clients/siddhi_1.png"
import Tomoae from "../../../assets/images/clients/tomoae.png"
import WasteManagement from "../../../assets/images/clients/waste.png"

import Amix from "../../../assets/images/clients/amix.png"
// import Amtmc from "../../../assets/images/clients/amtmc.png"
// import Balarpur from "../../../assets/images/clients/balarpur.jpg"
// import Dharmabad from "../../../assets/images/clients/dharmabad.jpeg"
// import Gems from "../../../assets/images/clients/gems.png"
// import Hansa from "../../../assets/images/clients/hansa.jpeg"
// import Jog from "../../../assets/images/clients/jog.png"
// import Narayana from "../../../assets/images/clients/narayana.jpg"
// import Poddar from "../../../assets/images/clients/poddar.png"
// import Police from "../../../assets/images/clients/police.png"
// import Saksham from "../../../assets/images/clients/saksham.png"
// import Sanjuba from "../../../assets/images/clients/sanjuba.png"
// import Wpsi from "../../../assets/images/clients/wpsi.png"
// import Xavier from "../../../assets/images/clients/xavier.png"

const ClientsData = [
    {
        // title: "Our Clients",
        // description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        items: [
            {
                image: BhavanVidyaMandir,
                name: "Bhavan's Vidya Mandir",
                count: 4,
                // pro: true,
            },
            {
                image: DelhiPublicSchool,
                name: "Delhi Public School",
                count: 7,
                // pro: true,
            },
            {
                image: Edify,
                name: "Edify School",
                count: 10,
                // pro: true,
            },
            {
                image: Gaikwad,
                name: "Gaikwad-Patil International School",
                count: 11,
                // pro: true,
            },
            {
                image: Mahalaxmi,
                name: "Mahakaxmi TMT Pvt Ltd",
                count: 16,
                // pro: true,
            },
            {
                image: Pooja,
                name: "Pooja Infraventures Pvt Ltd",
                count: 21,
                // pro: true,
            },
            {
                image: Siddhivinayak,
                name: "Siddhi Vinayak",
                count: 25,
                // pro: true,
            },
            {
                image: Tomoae,
                name: "Tomoae School Amaravati",
                count: 27,
                // pro: true,
            },
            {
                image: WasteManagement,
                name: "Waste Management",
                count: 28,
                // pro: true,
            },
            {
                image: Amix,
                name: "Amix Infra Concrete Solutions",
                count: 28,
                // pro: true,
            },
        ],
    },
];

function ClientScreen() {
    const renderData = ClientsData.map(({ title, description, items }) => (
        <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
            <Grid item xs={12} lg={3}>
                <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
                    <MKTypography variant="h3" fontWeight="bold" mb={1}>
                        {title}
                    </MKTypography>
                    <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                        {description}
                    </MKTypography>
                </MKBox>
            </Grid>
            <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                    {items.map(({ image, name, count, route, pro }) => (
                        <Grid item xs={12} md={3} sm={6} sx={{ mb: 2 }} key={name}>
                            <Link to={pro ? "/" : route}>
                                <ClientCard image={image} name={name} count={count} pro={pro} />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    ));
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <MKTypography variant="h2">Our Clients</MKTypography>
                    <Container sx={{ mt: 1 }}>{renderData}</Container>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default ClientScreen;
