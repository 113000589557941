// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// Images
import location from "../../../../assets/images/sections/global-present.png"
import ContactCard from "./Cards/ContactCard";

function ContactInfo({ color, title, description, direction, small }) {

    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container >
                    <Grid item xs={12} md={12} sx={{ mb: 5, textAlign: "center" }} justifyContent="center"
                        alignItems="center">
                        <MKTypography variant="h3" color="dark" mb={5}>
                            GET IN TOUCH
                        </MKTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={3} sx={{ mt: { xs: 3, lg: 0 } }}>
                        <ContactCard
                            title="HEAD OFFICE, WARDHA"
                            address="Gond Plot, R.T. Road,
                            Near old Pawde,
                            Nursing Home,
                            Wardha 442001, Maharashtra
                            India"
                            phoneNo="+91 9370240892"
                            email="info@dolphindls.in"
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{ mt: { xs: 3, lg: 0 } }}>
                        <ContactCard
                            title="BRANCH OFFICE, NAGPUR"
                            address="A-102, 1st Floor,
                            Riddhi Apartment, Queta Colony,
                            Itwari,
                            Nagpur - 440002, Maharashtra
                            India"
                            phoneNo="+91 9325118669"
                            email="info@dolphindls.in"
                        />
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
                        <MKBox component="img" src={location} alt="School" width="100%" />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default ContactInfo;
