// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-countup component
// import CountUp from "react-countup";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import MKTypography from "../../../../../components/MKTypography";

function ContactCard({ color, count, title, address, phoneNo, email }) {
    return (
        <MKBox p={2} lineHeight={1}>
            {title && (
                <MKTypography variant="h5" color={"warning"} textGradient>
                    {title}
                </MKTypography>
            )}
            {address && (
                <MKTypography variant="body2" color="text" my={2}>
                    {address}
                </MKTypography>
            )}
            {phoneNo && (
                <MKTypography variant="body2" color="text" my={2}>
                    <MKTypography variant="h6" color="dark">
                        Call Us:
                    </MKTypography>
                    {phoneNo}
                </MKTypography>
            )}
            {email && (
                <MKTypography variant="body2" color="text" my={2}>
                    <MKTypography variant="h6" color="dark">
                        EMAIL US:
                    </MKTypography>
                    {email}
                </MKTypography>
            )}
        </MKBox>
    );
}

// Setting default props for the DefaultCounterCard
ContactCard.defaultProps = {
    color: "info",
    description: "",
    title: "",
};

// Typechecking props for the DefaultCounterCard
ContactCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    count: PropTypes.number.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default ContactCard;
