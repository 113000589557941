/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";
import borders from "../../../../../assets/theme/base/borders";

// @mui material components
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import MKTypography from "../../../../../components/MKTypography";
import MKButton from "../../../../../components/MKButton";

function RotatingCardBack({ color, image, title, description, action }) {
    const { borderWidth } = borders;
    const inputBorderColor = "#e69206"
    return (
        <MKBox
            display="flex"
            // justifyContent="center"
            // alignItems="center"
            borderRadius="lg"
            coloredShadow={color}
            position="absolute"
            width="100%"
            height="100%"
            top={0}
            left={0}
            zIndex={5}
            sx={{
                backfaceVisibility: "hidden",
                transform: "rotateY(180deg)",
            }}
        >
            <MKBox
                pt={10}
                pb={2} px={5} textAlign="center" lineHeight={1}>
                <MKTypography variant="h3" color="Text" gutterBottom
                    sx={{
                        paddingBottom: 3,
                        borderBottom: `${borderWidth[2]} solid ${inputBorderColor}`,
                    }}
                >
                    {title}
                </MKTypography>
                <MKTypography variant="body2" color="Text" opacity={0.8} mt={4} textAlign={"justify"} lineHeight={1.8} textIndent={10}
                    style={{ textIndent: "50px" }}
                >
                    {description}
                </MKTypography>
                {action && (
                    <MKBox width="50%" mt={4} mb={2} mx="auto">
                        {action.type === "external" ? (
                            <MKButton
                                component={MuiLink}
                                href={action.route}
                                target="_blank"
                                rel="noreferrer"
                                color="white"
                                size="small"
                                fullWidth
                            >
                                {action.label}
                            </MKButton>
                        ) : (
                            <MKButton component={Link} to={action.route} color="white" size="small" fullWidth>
                                {action.label}
                            </MKButton>
                        )}
                    </MKBox>
                )}
            </MKBox>
        </MKBox >
    );
}

// Setting default props for the RotatingCard
RotatingCardBack.defaultProps = {
    color: "info",
};

// Typechecking props for the RotatingCard
RotatingCardBack.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
    ]),
    image: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
    action: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            type: PropTypes.oneOf(["external", "internal"]).isRequired,
            route: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ]).isRequired,
};

export default RotatingCardBack;
