// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import borders from "../../../../../assets/theme/base/borders";

// @mui material components
// import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../../../../components/MKBox";
import MKTypography from "../../../../../components/MKTypography";
import MKButton from "../../../../../components/MKButton";

function JobCard({ image, title, description, action }) {
    const { borderWidth, borderColor } = borders;
    // const inputBorderColor = "#e69206"

    return (
        // <Card
        //     sx={{
        //         borderRadius: "xs",
        //         border: `${borderWidth[1]} solid ${borderColor}`,
        //     }}
        // >
        <MKBox p={3}
            sx={{
                borderRadius: "sm",
                border: `${borderWidth[1]} solid ${borderColor}`,
            }}
        >
            <MKBox minHeight="10rem" my="auto" py={3}>
                <MKTypography
                    variant="h5"
                    color="text"
                >
                    {title}
                </MKTypography>
                <MKTypography variant="body2" textAlign={"justify"} color="text" my={1}>
                    Full-time
                </MKTypography>
                <MKTypography variant="body2" textAlign={"justify"}
                    color="dark"
                    my={1}
                    mb={2}
                >
                    {description}
                </MKTypography>
                <MKButton variant="outlined" color="warning">
                    Apply Now
                </MKButton>
            </MKBox>
        </ MKBox>
        // </Card>
    );
}

// Typechecking props for the BackgroundBlogCard
JobCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    action: PropTypes.shape({
        type: PropTypes.oneOf(["external", "internal"]).isRequired,
        route: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

export default JobCard;



// background-color: #7b55bc;
// background-image: linear-gradient(to bottom right, #ff9107, #32048e);    //yellow


// ackground: linear-gradient(to bottom left, #00A3A3 40%, #20C997 80%, #007BFF 100%);   //green
// background-image: linear-gradient(to bottom right,#00a7ff,#055386);      //blue