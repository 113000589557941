// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKTypography from "../../../../components/MKTypography";
import MKButton from "../../../../components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";
import CenteredFooter from "../../../../examples/Footers/CenteredFooter"

// icons
// import AndroidIcon from '@mui/icons-material/Android';
import AdbIcon from '@mui/icons-material/Adb';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';

// Routes
import routes from "../../../../routes";

// Images
// import bgImage from "../../../../assets/images/bg-about-us.jpg";
import WhyChooseUs from "./section/WhyChooseUs";
import AboutRigv from "./section/AboutRigv";
// import DownloadRigvApp from "./section/DownloadRigvApp";

function Rigv() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                variant="gradient"
                bgColor="success"
                sx={{
                    // backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                    //     `${linearGradient(
                    //         rgba(gradients.dark.main, 0.6),
                    //         rgba(gradients.dark.state, 0.6)
                    //     )}, url(${bgImage})`,
                    // backgroundSize: "cover",
                    // backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={10}
                        md={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography
                            variant="h1"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            RigV
                        </MKTypography>
                        <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        </MKTypography>

                        {/* <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                            create account
                        </MKButton> */}

                        <Grid container lg={7} spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MKButton
                                    variant="outlined"
                                    size="large"
                                    component="a"
                                    href="https://play.google.com/store/apps/details?id=com.dolphin.rigvapp"
                                    sx={{ mb: 2, ml: 2 }}
                                >
                                    <AdbIcon /> &nbsp;
                                    ANDROID
                                </MKButton>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MKButton
                                    variant="outlined"
                                    size="large"
                                    component="a"
                                    // href="https://play.google.com/store/apps/details?id=com.dolphin.rigvschool"
                                    href="https://apps.apple.com/in/app/rigv/id1592264605"
                                    sx={{ mb: 2, ml: 2 }}
                                >
                                    <AppleIcon /> &nbsp;
                                    Apple IOS
                                </MKButton>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MKButton
                                    variant="outlined"
                                    size="large"
                                    component="a"
                                    href="http://user.rigv.in/dashboard"
                                    sx={{ mb: 2, ml: 2 }}
                                >
                                    <LaptopWindowsIcon /> &nbsp;
                                    Web
                                </MKButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <AboutRigv />
                <Divider
                    sx={{ my: 6, mx: "15%" }}
                />
                <WhyChooseUs />
                {/* <DownloadRigvApp /> */}
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <CenteredFooter />
            </MKBox>
        </>
    );
}

export default Rigv;
