// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "../../../assets/images/team-5.jpg";
import man from "../../../assets/images/sections/men.png";
import women from "../../../assets/images/sections/woman.png";
import team2 from "../../../assets/images/bruce-mars.jpg";
import team3 from "../../../assets/images/ivana-squares.jpg";
import team4 from "../../../assets/images/ivana-square.jpg";
import TestimonialCard from "./card/TestimonialCard";

import bgImage from "../../../assets/images/sections/hd-3.jpeg"
import slider1 from "../../../assets/images/sections/Route-bg.jpeg"
import slider2 from "../../../assets/images/sections/bg-17.png"
import slider3 from "../../../assets/images/sections/product-background-img.png"

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// import { Carousel } from "react-responsive-carousel";

function TestimonialScreen() {
    // var settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };
    const settings = {
        // dots: true,
        // infinite: true,
        // slidesToShow: 2,
        // slidesToScroll: 1,
        // autoplay: true,
        // speed: 2000,
        // autoplaySpeed: 5000,
        // cssEase: "linear"
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
    };
    return (
        <MKBox
            component="section"
            variant="gradient"
            bgColor="light"
            position="relative"
            py={10}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        // sx={{
        //     backgroundImage: `url(${bgImage})`,
        //     backgroundSize: "cover",
        //     backgroundPosition: "top",
        //     display: "grid",
        //     placeItems: "center",
        // }}
        >
            <Container >
                <Grid container>
                    <Grid item xs={12} md={12} sx={{ mb: 5, textAlign: "center" }}>
                        <MKTypography variant="h3" fontWeight="bold" mb={2}
                        // textGradient color={"warning"}
                        >
                            Testimonials
                        </MKTypography>
                        <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                            Here's what our customer say about RigV!
                        </MKTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {/* <Grid container spacing={3} sx={{
                        mt: 1, minWidth: '60vh', overflowX: 'auto'
                    }}>
                        < Grid item xs={12} md={6} lg={4} >
                            <TestimonialCard
                                color="default"
                                name="Muzaffar Ahmad"
                                date="1 day ago"
                                review="Love how this app makes the morning rush a lot less stressful. No more waiting endlessly for the bus!."
                                rating={3}
                                // image={man}
                                image={team1}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TestimonialCard
                                // image={man}
                                image={team2}
                                // color="warning"
                                color="default"
                                name="mitesh puniyani"
                                date="1 week ago"
                                review="Nice app .good service from app to know actual location of the bus of children. Any technical issue is resolved in short time period. Satisfied 👍👍."
                                rating={5}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TestimonialCard
                                image={women}
                                color="default"
                                name="Aparna Mene"
                                date="3 weeks ago"
                                review="Finally, peace of mind for parents and guardians knowing exactly where their child's school bus is in real-time. A game-changer!."
                                rating={4}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TestimonialCard
                                // image={women}
                                image={team3}
                                color="default"
                                name="Priya Sadawarte"
                                date="3 weeks ago"
                                review="Very helpful app to track my kid's school bus. If there is any issue, the team responds immediately. Thank you for making our lives easier!👍🏻👍🏻."
                                rating={5}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TestimonialCard
                                // image={man}
                                image={team4}
                                color="default"
                                name="Manohar Dongare"
                                date="3 weeks ago"
                                review="Brilliant tool for ensuring children's safety. A must-have for every school and parent!."
                                rating={4}
                            />
                        </Grid>
                    </Grid> */}

                    {/* ========================================================= */}
                    {/* <Grid container spacing={3} sx={{
                        mt: 1, minWidth: '60vh', overflowX: 'auto'
                    }}>
                        <Carousel autoPlay>
                            <MKBox component="img" src={bgImage} alt="School" width="100%">
                                <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-1.jpg" />
                            <p className="legend">Legend 1</p>
                            </MKBox>
                            <MKBox component="img" src={bgImage} alt="School" width="100%">
                                <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-2.jpg" />
                            <p className="legend">Legend 2</p>
                            </MKBox>
                            <MKBox component="img" src={bgImage} alt="School" width="100%">
                                <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-3.jpg" />
                            <p className="legend">Legend 3</p>
                            </MKBox>
                            <MKBox component="img" src={bgImage} alt="School" width="100%">
                                <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-4.jpg" />
                            <p className="legend">Legend 4</p>
                            </MKBox>
                            <MKBox component="img" src={bgImage} alt="School" width="100%">
                                <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-5.jpg" />
                            <p className="legend">Legend 5</p>
                            </MKBox>
                            <MKBox component="img" src={bgImage} alt="School" width="100%">
                                <img alt="" src="http://lorempixel.com/output/cats-q-c-640-480-6.jpg" />
                            <p className="legend">Legend 6</p>
                            </MKBox>
                        </Carousel>
                    </Grid> */}


                    <Grid Grid item xs={12} md={12} lg={12} >
                        <Slider {...settings}>
                            <MKBox minWidth="100%" sx={{ px: 2, pt: 5 }}>
                                <TestimonialCard
                                    color="default"
                                    name="Muzaffar Ahmad"
                                    date="1 day ago"
                                    review="Love how this app makes the morning rush a lot less stressful. No more waiting endlessly for the bus!."
                                    rating={3}
                                    image={man}
                                // image={team1}
                                />
                            </MKBox>
                            <MKBox minWidth="100%" sx={{ px: 2, pt: 5 }}>
                                <TestimonialCard
                                    image={man}
                                    // image={team2}
                                    // color="warning"
                                    color="default"
                                    name="mitesh puniyani"
                                    date="1 week ago"
                                    review="Nice app .good service from app to know actual location of the bus of children. Any technical issue is resolved in short time period. Satisfied 👍👍."
                                    rating={5}
                                />
                            </MKBox>
                            <MKBox minWidth="100%" sx={{ px: 2, pt: 5 }}>
                                <TestimonialCard
                                    image={women}
                                    color="default"
                                    name="Aparna Mene"
                                    date="3 weeks ago"
                                    review="Finally, peace of mind for parents and guardians knowing exactly where their child's school bus is in real-time. A game-changer!."
                                    rating={4}
                                />
                            </MKBox>
                            <MKBox minWidth="100%" sx={{ px: 2, pt: 5 }}>
                                <TestimonialCard
                                    image={women}
                                    // image={team3}
                                    color="default"
                                    name="Priya Sadawarte"
                                    date="3 weeks ago"
                                    review="Very helpful app to track my kid's school bus. If there is any issue, the team responds immediately. Thank you for making our lives easier!👍🏻👍🏻."
                                    rating={5}
                                />
                            </MKBox>
                            <MKBox minWidth="100%" sx={{ px: 2, pt: 5 }}>
                                <TestimonialCard
                                    image={man}
                                    // image={team4}
                                    color="default"
                                    name="Manohar Dongare"
                                    date="3 weeks ago"
                                    review="Brilliant tool for ensuring children's safety. A must-have for every school and parent!."
                                    rating={4}
                                />
                            </MKBox>
                        </Slider>
                    </Grid>
                    {/* </Grid> */}
                </Grid>
            </Container >
        </MKBox >
    );
}

export default TestimonialScreen;
